<template>
  <div class="bottom-content flex-col">
    <img class="logo" src="../../../assets/icon/logo_icon2.png" alt="可搭吖">
    <p class="txt1">可搭吖，每次“搭吖”都是新故事的开始！
      这里不仅是社交平台，更是发现自我、结交闺蜜的魔法乐园。
    </p>
    <p class="txt2">一起来探索世界，找到更多志同道合的姐妹搭子吧！</p>
    <div class="platform align-center">
      <!-- <img src="../../../assets/img/weibo.png" alt="可搭吖"> -->
      <!-- <a target="_blank" href="https://weixin.qq.com/r/9hOlvXfEXCojrTPe90bk">
        <img src="../../../assets/img/weixin.png" alt="可搭吖">
      </a> -->
      <li class="platform-item">
        <img src="../../../assets/img/weixin.png" alt="可搭吖" @click="wxcodeShow = !wxcodeShow">
        <div class="qr-code" v-show="wxcodeShow">
          <img src="@/assets/img/wx_qrcode.png" alt="">
        </div>
      </li>
      <a target="_blank" href="https://v.douyin.com/iMDAycFk/">
        <img src="../../../assets/img/douyin.png" alt="可搭吖">
      </a>
      <a target="_blank" href="https://www.xiaohongshu.com/user/profile/6645a87e00000000030337d9">
        <img src="../../../assets/img/xiaohongshu.png" alt="可搭吖">
      </a>

    </div>
    <p class="txt3">联系方式：</p>
    <p class="txt4">邮箱：support@psydusk.com.cn</p>
    <p class="txt5">地址：深圳市南山区高新南七道惠恒大厦二期708</p>
    <div class="btn-box align-center">
      <a class="btn-item justify-center align-center" :href="appleDownloadUrl">
        <img src="../../../assets/img/app_store2.png" alt="可搭吖">
        <span>App Store</span>
      </a>
      <div class="btn-item justify-center align-center ml16" @click="downloadApk">
        <img src="../../../assets/img/android2.png" alt="可搭吖">
        <span>Android</span>
      </div>
    </div>
    <div class="footer-bottom flex-col">
      <span>Copyrights © 2024 可搭吖APP All Rights Reserved.</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">粤ICP备14024433号-16</a>
    </div>
  </div>
</template>

<script>
import { GetEnableLatestAppVersion } from '@/api/index'
export default {
  data() {
    return {
      appleDownloadUrl: '',
      androidDownloadUrl: '',
      androidAppVersion: '',
      wxcodeShow: false,
    }
  },
  mounted() {
    this.getEnableLatestAppVersion(1) // 获取apple 下载地址
    this.getEnableLatestAppVersion(2) // 获取android 下载地址
  },
  methods: {
    getEnableLatestAppVersion(kind) {
      GetEnableLatestAppVersion({ kind }).then((res) => {
        if (kind == 1) {
          this.appleDownloadUrl = res.app_version?.url || ''
          this.$emit('downloadUrl', {
            kind,
            url: res.app_version?.url || ''
          })
        } else {
          this.androidDownloadUrl = res.app_version?.url || ''
          this.androidAppVersion = res.app_version?.version || ''
          this.$emit('downloadUrl', {
            kind,
            url: res.app_version?.url || '',
            version: res.app_version?.version || ''
          })
        }
      })
    },
    isAppleDevice() {
      return /iPhone|iPad|iPod/.test(navigator.userAgent);
    },
    isWeChatBrowser() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(userAgent);
    },
    // 点击下载安卓app
    downloadApk() {
      // 安卓手机微信内打不开Blob，会提示“即将离开微信，前往浏览器打开”，但是会有问题，则执行普通的a标签下载
      if (!this.isAppleDevice() && this.isWeChatBrowser()) {
        let link = document.createElement('a');
        link.href = this.androidDownloadUrl;
        link.download = `kedaya_${this.androidAppVersion}.apk`; // 下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        fetch(this.androidDownloadUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `kedaya_${this.androidAppVersion}.apk`; // 设置下载文件的名称
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom-content {
  box-sizing: border-box;
  width: 100%;
  background-color: #333333;
  padding: 28rem 16rem 0;
  padding-bottom: calc(16rem + constant(safe-area-inset-bottom));
  padding-bottom: calc(16rem + env(safe-area-inset-bottom));

  .logo {
    width: 28rem;
    height: 28rem;
    margin-bottom: 12rem;
  }

  .txt1,
  .txt2 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 19rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .platform {
    margin-top: 12rem;

    .platform-item {
      position: relative;

      .qr-code {
        position: absolute;
        top: -110rem;

        img {
          width: auto;
          height: 100rem;
          object-fit: contain;
        }

      }
    }

    img {
      width: 32rem;
      height: 32rem;
      margin-right: 20rem;
    }
  }

  .txt3 {
    margin-top: 20rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 19rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .txt4 {
    margin-top: 12rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 14rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .txt5 {
    margin-top: 8rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 14rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .btn-box {
    margin-top: 16rem;

    .btn-item {
      width: 120rem;
      height: 36rem;
      border-radius: 9rem;
      border: 1px solid #FFFFFF;

      img {
        width: 21rem;
        height: 21rem;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 11rem;
        color: #FFFFFF;
        line-height: 12rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-left: 12rem;
      }
    }
  }

  .footer-bottom {
    width: 100%;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 19rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 20rem;

    a {
      color: rgba(255, 255, 255, 0.9);
      text-decoration: underline;
    }
  }
}
</style>
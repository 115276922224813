<template>
    <div>
        <div class="nav-box justify-between align-center"
            :style="scrollTopStyle ? 'background-color: transparent;' : 'background-color: #FFFFFF;box-shadow: 0px 3rem 5rem 0px rgba(51,51,51,0.02);'">
            <img class="img1" src="../../../assets/img/kedaya_icon.png" alt="可搭吖" @click="goHome">
            <img v-if="!showPopup" class="img2" src="../../../assets/icon/menu.png" alt="可搭吖" @click="onShowPopup">
            <img v-else class="img2" src="../../../assets/icon/close.png" alt="可搭吖" @click="onHidePopup">
        </div>
        <div v-if="showPopup" class="popup-box flex-col" :style="'height:' + popupHeight + 'vh'">
            <div class="popup-item justify-between align-center" @click="goHome">
                <span>首页</span>
            </div>
            <div class="popup-item justify-between align-center" @click="goContactUs">
                <span>联系我们</span>
            </div>
            <div class="popup-item justify-between align-center" @click="showSelect = !showSelect">
                <span>协议</span>
                <img v-if="showSelect" src="../../../assets/icon/up.png" alt="可搭吖">
                <img v-else src="../../../assets/icon/down.png" alt="可搭吖">
            </div>
            <div v-if="showSelect" class="select-box flex-col">
                <div class="select-item align-center" @click="goAgreement"> 用户协议 </div>
                <div class="select-item align-center" @click="goPolicy"> 隐私政策 </div>
                <div class="select-item align-center" @click="goStandard"> 社区规范 </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopup: false,
            scrollTopStyle: true,
            popupHeight: 0,
            showSelect: false,
        }
    },
    mounted() {
        window.onscroll = () => {
            const t = document.documentElement.scrollTop || document.body.scrollTop;
            this.scrollTopStyle = t <= 20;
        };
    },
    methods: {
        goHome() {
            this.onHidePopup()
            this.$router.push('/mobilehome');
        },
        goContactUs() {
            this.onHidePopup()
            this.$router.push('/mobileconnectUs');
        },
        goAgreement() {
            this.onHidePopup()
            this.$router.push(
                {
                    name: "MobileAgreement",
                }
            );
        },
        goPolicy() {
            this.onHidePopup()
            this.$router.push(
                { name: "MobilePolicy" }
            );
        },
        goStandard() {
            this.onHidePopup()
            this.$router.push({
                name: "MobileStandard",
            });
        },
        onShowPopup() {
            this.showPopup = true;
            document.body.style.overflow = 'hidden'; // 禁止滚动
            this.$nextTick(() => {
                setTimeout(() => {
                    this.popupHeight = 100;
                }, 0);
            });
        },
        onHidePopup() {
            this.popupHeight = 0;
            setTimeout(() => {
                this.showPopup = false;
                this.showSelect = false;
                document.body.style.overflow = ''; // 恢复滚动
            }, 300);
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-box {
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 375rem;
    height: 50rem;
    padding: 11rem 16rem;
    z-index: 1001;

    .img1 {
        width: 300rem;
        height: 28rem;
    }

    .img2 {
        width: 20rem;
        height: 20rem;
    }
}

.popup-box {
    box-sizing: border-box;
    width: 375rem;
    background-color: #FFFFFF;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all 0.3s;
    padding-top: 50rem;

    .popup-item {
        box-sizing: border-box;
        width: 100%;
        height: 42rem;
        padding: 10rem 0;
        margin-top: 15rem;

        span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16rem;
            color: #333333;
            line-height: 19rem;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-left: 16rem;
        }

        img {
            width: 20rem;
            height: 20rem;
            margin-right: 16rem;
        }
    }

    .select-box {
        background: #F9F9F9;
        box-sizing: border-box;
        width: 100%;
        height: 178rem;
        padding: 14rem 0;

        .select-item {
            width: 100%;
            height: 40rem;
            box-sizing: border-box;
            padding-left: 16rem;
            margin-top: 15rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14rem;
            color: #666666;
            line-height: 16rem;
            text-align: left;
            font-style: normal;
            text-transform: none;

            &:nth-child(1) {
                margin-top: 0;
            }
        }
    }
}
</style>